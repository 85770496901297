<template>
  <section
    oncontextmenu="return false"
    ondragstart="return false"
    onselectstart="return false"
    class="container mx-auto px-4 pdf-item py-24"
  >
    <div class="mx-auto prose text-justify">
      <h1 class="text-center mb-4 text-gray-900 font-bold">TERMOS DE USO</h1>

      <p class="pdf-item">
        O uso desta plataforma digital por {{ nm_rz_social }} ("{{
          nm_fantasia
        }}") está condicionado à aceitação e cumprimento aos Termos e Condições
        de Uso e Serviços (“Termos de Uso”) descritos abaixo, os quais estão
        adequados às exigências contidas na Lei n. 10.406/2002 (“Código Civil
        Brasileiro”), na Lei n. 12.965/2014 (“Marco Civil da Internet”) e em
        demais legislações pertinentes.
      </p>

      <p class="pdf-item">
        Nestes Termos de Uso, junto de nossas Políticas de Privacidade,
        encontram-se dispostas todas as regras e condições aplicáveis ao acesso,
        uso dos serviços e operações desta plataforma digital. Estes serviços
        são oferecidos por {{ nm_rz_social }} (“{{ nm_fantasia }}”), inscrita no
        CNPJ sob o n.º {{ formatCNPJ(cd_cnpj) }}, endereço:
        {{ nm_endereco_logradouro }}, n° {{ cd_endereco_numero }},
        {{ nm_endereco_complemento }}, {{ nm_endereco_bairro }},
        {{ nm_endereco_cidade }}, CEP {{ formatCEP(cd_endereco_cep) }}.
      </p>

      <p class="pdf-item">
        A utilização de nossos serviços fica submetida à prévia aceitação destes
        Termos de Uso, sendo necessário ao nosso Usuário a sua prévia leitura e
        concordância com nossas regras e Políticas de Privacidade. Após a
        leitura dos Termos e Condições e da Política de Privacidade, o Usuário
        confirma que compreendeu e aceitou todos os seus termos e condições.
      </p>

      <p class="pdf-item">
        {{ nm_fantasia }} poderá modificar estes Termos e Condições a qualquer
        tempo, mediante notificação prévia dos Usuários, a ser enviada ao e-mail
        cadastrado pelo Usuário na Plataforma ou mediante a disponibilização de
        aviso na própria Plataforma. O uso da Plataforma ou dos Serviços após a
        entrada em vigor das modificações dos Termos e Condições constituirá
        aceitação e concordância do Usuário quanto aos novos termos contratuais.
      </p>

      <p class="pdf-item">
        Na hipótese de o Usuário não concordar com as alterações ou
        modificações, este poderá, a qualquer momento, solicitar o cancelamento
        de sua Conta, conforme previsto no item 2.4. destes Termos e Condições.
      </p>

      <p class="underline pdf-item">
        PARA MANIFESTAR SUA CONCORDÂNCIA COM ESTES TERMOS DE USO, O USUÁRIO DEVE
        CLICAR EM “EU ACEITO”, ACEITANDO TODAS AS REGRAS PREVISTAS PARA
        UTILIZAÇÃO DA PLATAFORMA DIGITAL. TODAS ESTAS DISPOSIÇÕES REGULAM A
        RELAÇÃO ENTRE {{ nm_fantasia }} E O USUÁRIO. NA HIPÓTESE DO USUÁRIO NÃO
        CONCORDAR COM OS TERMOS DE USO, ESTE NÃO PODERÁ ACESSAR A PLATAFORMA OU
        UTILIZAR NOSSOS SERVIÇOS.
      </p>

      <template v-if="is_permitido_criar_conta">
        <h3>REGISTRO DE USUÁRIO</h3>

        <p class="pdf-item">
          Para a utilização da plataforma, o Usuário – pessoa física ou jurídica
          que esteja em pleno e total gozo da capacidade civil, bem como esteja
          apto à prática de todo e qualquer ato necessário à validade das
          solicitações de serviços requeridas, nos termos da legislação
          aplicável – deverá realizar seu cadastro na plataforma.
        </p>

        <p class="pdf-item">
          Ao se cadastrar, o Usuário se compromete a fornecer informações
          verídicas, completas e atualizadas, sob pena das consequências
          jurídicas e legais da apresentação de informações falsas
        </p>

        <p class="underline pdf-item">
          O login e a senha criados pelo Usuário são pessoais e intransferíveis,
          sendo o Usuário seu único e exclusivo responsável por mantê-las em
          segurança e sigilo, evitando, pois, o uso não autorizado de seu
          Cadastro por terceiros.
        </p>
      </template>

      <template
        v-if="
          is_vendido_produtos || is_servico_ecommerce || is_servico_sass_sub
        "
      >
        <h3>PRODUTOS</h3>

        <p class="underline pdf-item">
          Fazemos todos os esforços para exibir com a maior precisão possível as
          cores, características, especificações e detalhes dos produtos
          disponíveis no Site. No entanto, não garantimos que as cores,
          recursos, especificações e detalhes dos produtos sejam precisos,
          completos, confiáveis, atuais ou livres de outros erros. Além disso,
          seu display eletrônico pode não refletir com precisão as cores e
          detalhes reais dos produtos.
        </p>

        <p class="underline pdf-item">
          Os produtos estão sujeitos a disponibilidade, e não podemos garantir
          que os itens estarão em estoque.
        </p>

        <p class="underline pdf-item">
          Por fim, nós nos reservamos o direito de descontinuar qualquer produto
          a qualquer momento por qualquer motivo bem como alterar preços.
        </p>
      </template>
      <div class="sidebar-box">
        <p class="pdf-item">
          Ao nos conceder acesso a quaisquer contas de terceiros, você entende
          que (1) podemos acessar, disponibilizar e armazenar (se aplicável)
          qualquer conteúdo que você forneceu e armazenado em sua conta de
          terceiros para que esteja disponível através desta plataforma digital
          por meio de sua conta, incluindo, sem limitação, quaisquer listas de
          amigos e (2) podemos enviar e receber de sua conta de terceiros
          informações adicionais, na medida em que você for notificado quando
          vinculá-la à nossa plataforma.
        </p>

        <p>
          As contas de terceiros utilizadas estão sujeitas às configurações de
          privacidade que você definiu em tais serviços.
        </p>
        <p class="read-more">
          <button
            @click="() => handleNavigateCheckout(this.$router)"
            style="color: white"
            class="rounded bg-indigo-700 px-4 hover:bg-indigo-600 transition-all duration-100 ease-in shadow-lg cursor-pointer text-white w-full py-3 mt-1"
          >
            Pagar o meu acesso
          </button>
        </p>
      </div>
    </div>
  </section>
</template>
<script>
import { functions } from '@/utils/functions';

export default {
  name: 'TermosDeUso',
  created() {},
  methods: {},
  mixins: [functions],
  props: {
    nm_rz_social: {
      type: String,
      default: '',
      required: false,
    },
    nm_fantasia: {
      type: String,
      default: '',
      required: false,
    },
    cd_cnpj: {
      type: String,
      default: '',
      required: false,
    },
    nm_endereco_logradouro: {
      type: String,
      default: '',
      required: false,
    },
    cd_endereco_numero: {
      type: String,
      default: '',
      required: false,
    },
    nm_endereco_complemento: {
      type: String,
      default: '',
      required: false,
    },
    nm_endereco_bairro: {
      type: String,
      default: '',
      required: false,
    },
    nm_endereco_cidade: {
      type: String,
      default: '',
      required: false,
    },
    cd_endereco_cep: {
      type: String,
      default: '',
      required: false,
    },
    is_permitido_criar_conta: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_servico_ecommerce: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_servico_sass_sub: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_vendido_produtos: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_aceito_pagamento: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_aceito_cartao_debito: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_aceito_cartao_credito: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_aceito_boleto: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_aceito_picpay: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_aceito_paypal: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_aceito_outros: {
      type: Boolean,
      default: false,
      required: false,
    },
    ds_outros_pagamentos: {
      type: String,
      default: '',
      required: false,
    },
    is_permitido_vincular_conta: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_permitido_avaliacoes: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_atividades_proibidas: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_proibido_alterar_violar: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_proibido_promover_cortes_comunicacao: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_proibido_monitorar_transimitir_virus: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_proibido_induzir_error: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_proibido_impedir_acesso_site: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_proibido_outros: {
      type: Boolean,
      default: false,
      required: false,
    },
    ds_proibido_outros: {
      type: String,
      default: '',
      required: false,
    },
    li_foro_cidades: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    is_nova_clausula: {
      type: Boolean,
      default: false,
      required: false,
    },
    ds_nova_clausula: {
      type: String,
      default: '',
      required: false,
    },
    dt_entrada_vigor: {
      type: String,
      default: '',
      required: false,
    },
  },
};
</script>

<style>
.list-lower-alpha {
  counter-reset: list;
  list-style: lower-alpha;
}

.list-lower-alpha > li::before {
  content: '' !important;
}
.sidebar-box {
  max-height: 140px;
  position: relative;
  overflow: hidden;
}
.sidebar-box .read-more {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 80px 0;

  /* "transparent" only works here because == rgba(0,0,0,0) */
  background-image: linear-gradient(to bottom, transparent, #fff);
}
</style>
